import React, { useState, useEffect } from "react";
import { Table, Card, Space } from "lib";
import {
  Button,
  TablePaginationConfig,
  message,
  Form,
  Input,
  Modal,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { Layout } from "layout";
import {
  ReportTemplatesClient,
  ReportTemplateBriefDto,
  ReportGenerationMethod,
} from "@api";
import { showError } from "@action";
import { FormattedMessage,useIntl } from "react-intl";
import ReportsMenu from "@components/Admin/Reports/ReportsMenu";
import { ACTION, SECTION, SubpageAuth } from "@services/auth";
import { navigate } from "gatsby";
import GeneralFormModal from "@components/GeneralFormModal";
import { DefaultOptionType } from "antd/lib/select";
import { ColumnType } from "antd/lib/table";

const defaultOrderBy = "created";
const defaultSortOrder = "descend";
const defaultPagination: TablePaginationConfig = {
  current: 1,
  pageSize: 10,
  position: ["topRight", "bottomRight"],
};

function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

const Page = () => {
  const auth = new SubpageAuth(SECTION.Admin, "Reports", "ReportTemplates");
  if (auth.cannot(ACTION.Access)) {
    navigate("/403/");
    return <></>;
  }

  const [modal, modalContextHolder] = Modal.useModal();
  const [messageApi, messageContextHolder] = message.useMessage();
  const [form] = Form.useForm();
  const [createModalVisible, setCreateModalVisible] = useState(false);

  const allLanguageFetch = {
    fetch(url: RequestInfo, init?: RequestInit): Promise<Response> {
      const _init = {
        ...init,
        headers: {
          ...init?.headers,
          "accept-language": "*",
        },
      };
      return fetch(url, _init);
    },
  };
  const reportTemplatesClient = new ReportTemplatesClient(
    undefined,
    allLanguageFetch
  );

  const [state, setState] = useState({
    items: [] as ReportTemplateBriefDto[],
    pagination: defaultPagination,
    loading: false,
  });
  const { formatMessage } = useIntl();
  const StaticKeywords = formatMessage({ id: "StaticKeywords" });
  const [keywords, setKeywords] = useState(
    undefined as string | undefined | null
  );

  async function fetchItems(
    params: any = { pagination: state.pagination },
    _keywords: string | undefined | null = undefined
  ) {
    if (auth.cannot(ACTION.List, "ReportTemplates")) return;

    setState({
      ...state,
      loading: true,
    });

    try {
      const res = await reportTemplatesClient.getReportTemplatesWithPagination(
        _keywords ?? keywords,
        params.pagination.current,
        params.pagination.pageSize,
        capitalizeFirstLetter(params.sortField ?? defaultOrderBy),
        (params.sortOrder ?? defaultSortOrder) == "ascend"
      );
      setState({
        ...state,
        items:
          (res.items as ReportTemplateBriefDto[]) ||
          ([] as ReportTemplateBriefDto[]),
        pagination: {
          ...params.pagination,
          total: res.totalCount,
        },
        loading: false,
      });
    } catch (err) {
      setState({
        ...state,
        loading: false,
      });
      showError(err);
    }
  }

  async function handleTableChange(
    pagination: TablePaginationConfig,
    filters: any,
    sorter: any
  ) {
    await fetchItems({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      ...filters,
    });
  }

  async function handleAddContent(data: any) {
    const hide = messageApi.loading(
      <FormattedMessage id="StaticCreatingMessage" />
    );
    try {
      await reportTemplatesClient.create(data);
      messageApi.success(<FormattedMessage id="StaticCreateSuccessMessage" />);
      setCreateModalVisible(false);
      await fetchItems();
    } catch (err) {
      showError(err);
    } finally {
      hide();
    }
  }

  function handleCancelAddContent() {
    setCreateModalVisible(false);
  }

  async function handleDelete(id: number) {
    modal.confirm({
      title: <FormattedMessage id="StaticConfirmDeleteMessage" />,
      okText: <FormattedMessage id="StaticYesLabel" />,
      cancelText: <FormattedMessage id="StaticNoLabel" />,
      async onOk() {
        const hide = messageApi.loading(
          <FormattedMessage id="StaticDeletingMessage" />
        );
        try {
          await reportTemplatesClient.delete(id);
          messageApi.success(
            <FormattedMessage id="StaticDeleteSuccessMessage" />
          );
          fetchItems();
        } catch (err) {
          showError(err);
        } finally {
          hide();
        }
      },
    });
  }

  async function handleShowDetailPage(id: number) {
    navigate("/admin/report/reporttemplate/" + id);
  }

  async function handleKeywordsSearch(values: any) {
    setKeywords(values.keywords);
    await fetchItems(undefined, values.keywords);
  }

  const columns: ColumnType<ReportTemplateBriefDto>[] = [
    // {
    //   title: "ID",
    //   dataIndex: "id",
    //   key: "id",
    //   sorter: true,
    // },
    {
      // title: "Name",
      title: <FormattedMessage id="ReportReportTemplateTableHeaderName" />,
      dataIndex: "name",
      key: "name",
      sorter: true,
    },
    {
      // title: "Display Name",
      title: <FormattedMessage id="ReportReportTemplateTableHeaderDisplayName" />,
      dataIndex: "displayName",
      key: "displayName",
      sorter: true,
    },
    {
      // title: "File Name",
      title: <FormattedMessage id="ReportReportTemplateTableHeaderFileName" />,
      dataIndex: "fileName",
      key: "fileName",
      sorter: true,
    },
    {
      // title: "Language",
      title: <FormattedMessage id="ReportReportTemplateTableHeaderLanguage" />,
      dataIndex: "preferredLanguageDisplayName",
      key: "preferredLanguageDisplayName",
      sorter: true,
    },
    {
      // title: "Generation Method",
      title: <FormattedMessage id="ReportReportTemplateTableHeaderGenerationMethod" />,
      dataIndex: "reportGenerationMethod",
      key: "reportGenerationMethod",
      sorter: true,
      render: (a: number) => <>{ReportGenerationMethod[a]}</>,
    },
    {
      // title: "Time added",
      title: <FormattedMessage id="ReportReportTemplateTableHeaderTimeAdded" />,
      dataIndex: "created",
      key: "created",
      sorter: true,
      defaultSortOrder,
      render: (d: Date) => <>{d?.toLocaleString()}</>,
    },
    {
      // title: "Action",
      title: <FormattedMessage id="ReportReportTemplateTableHeaderAction" />,
      key: "action",
      render: (text: any, record: ReportTemplateBriefDto) => (
        <Space>
          {auth.can(ACTION.Edit, "ReportTemplates") ? (
            <Button
              type="primary"
              onClick={() => handleShowDetailPage(record.id as number)}
            >
              <FormattedMessage id="StaticDetailsButton" />
            </Button>
          ) : null}
          {auth.can(ACTION.Delete, "ReportTemplates") ? (
            <Button
              type="primary"
              onClick={() => handleDelete(record.id as number)}
            >
              <FormattedMessage id="StaticDeleteButton" />
            </Button>
          ) : null}
        </Space>
      ),
    },
  ];

  useEffect(() => {
    fetchItems();
  }, []);

  return (
    <Layout title="ReportReportTemplateNavigationTitle">
      <Card>
        <Space direction="vertical" size="middle">
          <ReportsMenu />
          <Space justify="space-between">
            <Space justify="flex-start">
              <Form
                form={form}
                name="horizontal_login"
                layout="inline"
                onFinish={handleKeywordsSearch}
              >
                <Form.Item name="keywords">
                  <Input placeholder={StaticKeywords}  />
                </Form.Item>
                <Form.Item shouldUpdate>
                  {() => (
                    <Button type="primary" htmlType="submit">
                      <FormattedMessage id="StaticSearchLabel" />
                    </Button>
                  )}
                </Form.Item>
              </Form>
            </Space>
            <Space wrap justify="flex-end">
              {auth.can(ACTION.Create, "ReportTemplates") ? (
                <Button
                  type="primary"
                  onClick={() => {
                    setCreateModalVisible(true);
                  }}
                >
                  <Space>
                    <PlusOutlined />
                    <FormattedMessage id="ReportReportTemplateContentAddButton" />
                  </Space>
                </Button>
              ) : null}
            </Space>
          </Space>
          <Table
            bordered
            dataSource={[...state.items]}
            columns={columns}
            rowKey={(r) => r.id}
            pagination={state.pagination}
            loading={state.loading}
            onChange={handleTableChange}
          />
        </Space>
      </Card>
      <GeneralFormModal
        visible={createModalVisible}
        handleAction={handleAddContent}
        handleCancelAction={handleCancelAddContent}
        titleId={"ReportReportTemplateModalAddTitle"}
        okTextId={"StaticCreateButton"}
        editableFields={[
          {
            name: "name",
            labelId: "ReportReportTemplateStaticNameLabel",
            type: "string",
            rules: [
              {
                required: true,
                message: (
                  <FormattedMessage id="ReportReportTemplateStaticMissingNameRule" />
                ),
              },
            ],
          },
          {
            name: "displayName",
            labelId: "ReportReportTemplateStaticDisplayNameLabel",
            type: "string",
            rules: [
              {
                required: true,
                message: (
                  <FormattedMessage id="ReportReportTemplateStaticMissingDisplayNameRule" />
                ),
              },
            ],
          },
          {
            name: "preferredLanguageId",
            labelId: "ReportReportTemplateStaticLanguageLabel",
            type: "lang",
            rules: [
              {
                required: true,
                message: (
                  <FormattedMessage id="ReportReportTemplateStaticMissingLanguageRule" />
                ),
              },
            ],
          },
          {
            name: "reportGenerationMethod",
            labelId: "ReportReportTemplateStaticGenerationMethodLabel",
            type: "select",
            options: Object.keys(ReportGenerationMethod)
              .filter((a) => isNaN(Number(a)))
              .map((a): DefaultOptionType => {
                return {
                  label: a,
                  value:
                    ReportGenerationMethod[
                      a as keyof typeof ReportGenerationMethod
                    ],
                };
              }),
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            name: "showUnmatchToken",
            labelId: "ReportReportTemplateStaticShowUnmatchTokenLabel",
            type: "bool",
            rules: [],
          },
        ]}
      />
      {modalContextHolder}
      {messageContextHolder}
    </Layout>
  );
};

export default Page;
